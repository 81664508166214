var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: { copyData: _vm.insurancePlanCopy },
              on: {
                "update:copyData": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                save: _vm.validationForm,
                cancel: _vm.cancel,
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "b-form",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-alert",
                            { attrs: { variant: "primary", show: "" } },
                            [
                              _c("div", { staticClass: "alert-body" }, [
                                _c(
                                  "span",
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-25",
                                      attrs: {
                                        icon: "AlertCircleIcon",
                                        size: "18",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  Data on this page is derived from the\n                  "
                                    ),
                                    _c("strong", [_vm._v("company info")]),
                                    _vm._v(
                                      "\n                  , you can override specific fields by clicking the\n                  "
                                    ),
                                    _c("feather-icon", {
                                      staticClass:
                                        "ml-30 mr-30 secondary-text cursor-pointer",
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                    _vm._v(
                                      "\n                  icon\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("input-override", {
                            attrs: {
                              overrideData: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.claimsPayorId
                                : "",
                              label: "Claims Payer ID",
                            },
                            model: {
                              value: _vm.insurancePlanCopy.claimsPayorId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "claimsPayorId",
                                  $$v
                                )
                              },
                              expression: "insurancePlanCopy.claimsPayorId",
                            },
                          }),
                          _c("input-override", {
                            attrs: {
                              overrideData: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.statusPayorId
                                : "",
                              label: "Status Payer ID",
                            },
                            model: {
                              value: _vm.insurancePlanCopy.statusPayorId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "statusPayorId",
                                  $$v
                                )
                              },
                              expression: "insurancePlanCopy.statusPayorId",
                            },
                          }),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Clearing House",
                                "label-for": "basicInput",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Clearing House",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("single-select-override", {
                                          attrs: {
                                            id: "clearingHouse",
                                            optionsRepo: _vm.clearnigHouseRepo,
                                            optionsLabel: "name",
                                            overrideData: _vm.insurancePlanCopy
                                              .insurance
                                              ? _vm.insurancePlanCopy.insurance
                                                  .clearingHouseId
                                              : null,
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .clearingHouseId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "clearingHouseId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.clearingHouseId",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("br"),
                          _c("br"),
                          _c(
                            "can",
                            { attrs: { I: "read", a: "ClaimRules" } },
                            [
                              _c("h5", { staticClass: "mb-1" }, [
                                _c("b", [
                                  _vm._v("Filing Limits "),
                                  _c("span", { staticClass: "grey-text" }, [
                                    _vm._v("(in Days)"),
                                  ]),
                                ]),
                              ]),
                              _c("input-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .inNetworkFilingLimit
                                    : "",
                                  type: "number",
                                  label: "In network",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlanCopy.inNetworkFilingLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "inNetworkFilingLimit",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "insurancePlanCopy.inNetworkFilingLimit",
                                },
                              }),
                              _c("input-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .outOfNetworkFilingLimit
                                    : "",
                                  type: "number",
                                  label: "Out of network",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlanCopy
                                      .outOfNetworkFilingLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "outOfNetworkFilingLimit",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "insurancePlanCopy.outOfNetworkFilingLimit",
                                },
                              }),
                              _c("input-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .correctedClaimFilingLimit
                                    : "",
                                  type: "number",
                                  label: "Corrected claims",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlanCopy
                                      .correctedClaimFilingLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "correctedClaimFilingLimit",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "insurancePlanCopy.correctedClaimFilingLimit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            attrs: {
                              addressOverride: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.statusAddress
                                : {},
                              withOverride: true,
                            },
                            model: {
                              value: _vm.addressCopy,
                              callback: function ($$v) {
                                _vm.addressCopy = $$v
                              },
                              expression: "addressCopy",
                            },
                          }),
                          _c("br"),
                          _c("br"),
                          _c(
                            "can",
                            { attrs: { I: "read", a: "ClaimRules" } },
                            [
                              _c("h5", { staticClass: "mb-1" }, [
                                _c("b", [
                                  _vm._v("Accepts claims electronically"),
                                ]),
                              ]),
                              _c("radio-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .acceptsSecondaryClaimsE
                                    : "",
                                  label:
                                    "Accepts secondary claims electronically?",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlanCopy
                                      .acceptsSecondaryClaimsE,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "acceptsSecondaryClaimsE",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "insurancePlanCopy.acceptsSecondaryClaimsE",
                                },
                              }),
                              _c("radio-override", {
                                attrs: {
                                  overrideData: _vm.insurancePlanCopy.insurance
                                    ? _vm.insurancePlanCopy.insurance
                                        .acceptsCorrectedClaimsE
                                    : "",
                                  label:
                                    "Accepts corrected claims electronically?",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlanCopy
                                      .acceptsCorrectedClaimsE,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlanCopy,
                                      "acceptsCorrectedClaimsE",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "insurancePlanCopy.acceptsCorrectedClaimsE",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("email-phon-fax-override", {
                            attrs: {
                              sourceEmails: _vm.insurancePlanCopy.statusEmails,
                              sourcePhones: _vm.insurancePlanCopy.statusPhones,
                              overrideEmails: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.statusEmails
                                : [],
                              overridePhones: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.statusPhones
                                : [],
                            },
                            on: {
                              "update:sourceEmails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "statusEmails",
                                  $event
                                )
                              },
                              "update:source-emails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "statusEmails",
                                  $event
                                )
                              },
                              "update:sourcePhones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "statusPhones",
                                  $event
                                )
                              },
                              "update:source-phones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "statusPhones",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mt-5", attrs: { cols: "12" } },
                        [
                          _c("h5", { staticClass: "mb-1" }, [
                            _c("b", [
                              _vm._v("Web Links (Log-In Details And Methods)"),
                            ]),
                          ]),
                          _c("weblinks-cc", {
                            attrs: {
                              webLinksToFormField: "insurancePlanId",
                              filterByField: "InsurancePlanId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }