var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-component", {
        attrs: { copyData: _vm.insurancePlanCopy, validate: true },
        on: {
          "update:copyData": function ($event) {
            _vm.insurancePlanCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.insurancePlanCopy = $event
          },
          save: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4", sm: "6" } },
                      [
                        _c("h5", { staticClass: "mb-1" }, [
                          _vm._v("Deductibles"),
                        ]),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Has deductible" } },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: {
                                name: "HasDeductible",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasDeductible",
                                            value: true,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasDeductible,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasDeductible",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasDeductible",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Yes\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasDeductible",
                                            value: false,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasDeductible,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasDeductible",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasDeductible",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                No\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Restricted to this age and above",
                              "label-for": "ageRestriction",
                            },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: "AgeRestriction",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "ageRestriction",
                                          required: "",
                                        },
                                        model: {
                                          value:
                                            _vm.insurancePlanCopy
                                              .deductibleMinAge,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.insurancePlanCopy,
                                              "deductibleMinAge",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "insurancePlanCopy.deductibleMinAge",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Has a Co-Pay" } },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: { name: "HasCoPay", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasCoPay",
                                            value: true,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy.hasCopay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasCopay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasCopay",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Yes\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasCoPay",
                                            value: false,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy.hasCopay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasCopay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasCopay",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                No\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Has a Co-Insurance" } },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: {
                                name: "HasCoInsurance",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasCoInsurance",
                                            value: true,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasCoInsurance,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasCoInsurance",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasCoInsurance",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Yes\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "HasCoInsurance",
                                            value: false,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasCoInsurance,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasCoInsurance",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasCoInsurance",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                No\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4", sm: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Has Out of network benefits" } },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: {
                                name: "OutOfNetwork",
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "OutOfNetwork",
                                            value: true,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasOutOonBenefits,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasOutOonBenefits",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasOutOonBenefits",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Yes\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "OutOfNetwork",
                                            value: false,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .hasOutOonBenefits,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "hasOutOonBenefits",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.hasOutOonBenefits",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                No\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Is good Payer OON" } },
                          [
                            _c("validation-provider", {
                              staticClass: "demo-inline-spacing pt-0",
                              attrs: { name: "IsGoodPayer", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "IsGoodPayer",
                                            value: true,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .isGoodPayerOon,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "isGoodPayerOon",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.isGoodPayerOon",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                Yes\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            name: "IsGoodPayer",
                                            value: false,
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.insurancePlanCopy
                                                .isGoodPayerOon,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.insurancePlanCopy,
                                                "isGoodPayerOon",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "insurancePlanCopy.isGoodPayerOon",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                No\n              "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }