var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            { staticClass: "px-2" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                    _c("div", { staticClass: "alert-body" }, [
                      _c(
                        "span",
                        [
                          _c("feather-icon", {
                            staticClass: "mr-25",
                            attrs: { icon: "AlertCircleIcon", size: "18" },
                          }),
                          _vm._v(
                            "\n              Data on this page is derived from the\n              "
                          ),
                          _c("strong", [_vm._v("company info")]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("b-col", { staticClass: "mt-1 pl-2", attrs: { cols: "12" } }, [
                _c("h5", [
                  _c("b", [
                    _vm._v(
                      "Specialties that need a referral and/or authorization"
                    ),
                  ]),
                ]),
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "table-component",
                    {
                      attrs: {
                        isAddEditSidbar: true,
                        isDataFromModel: true,
                        fields: _vm.fields,
                        sidebarOpen: _vm.sidebar,
                        sidebarData: _vm.specieltyData,
                        copyData: _vm.specieltyDataCopy,
                        itemsRepo: _vm.authorizationSettingsRepo,
                        deleteFilter: function (x) {
                          return x.insurancePlanId
                        },
                        getByIdOnEditFilter: function (x) {
                          return x.insurancePlanId
                        },
                      },
                      on: {
                        "update:sidebarOpen": function ($event) {
                          _vm.sidebar = $event
                        },
                        "update:sidebar-open": function ($event) {
                          _vm.sidebar = $event
                        },
                        "update:sidebarData": function ($event) {
                          _vm.specieltyData = $event
                        },
                        "update:sidebar-data": function ($event) {
                          _vm.specieltyData = $event
                        },
                        "update:copyData": function ($event) {
                          _vm.specieltyDataCopy = $event
                        },
                        "update:copy-data": function ($event) {
                          _vm.specieltyDataCopy = $event
                        },
                        save: _vm.validationForm,
                        "on-delete": _vm.onDelete,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "cell(specialty)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(data.item.specialty.name) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(procedureSource)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    data.item.insuranceId
                                      ? "INSURANCE"
                                      : "INSURANCE PLAN"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(needReferral)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    data.item.needReferral == true
                                      ? "YES"
                                      : "NO"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(needAuthrization)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    data.item.needAuthrization == true
                                      ? "YES"
                                      : "NO"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.tableItems,
                        callback: function ($$v) {
                          _vm.tableItems = $$v
                        },
                        expression: "tableItems",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _c(
                            "validation-observer",
                            { ref: "simpleRules" },
                            [
                              _c(
                                "b-form",
                                [
                                  _c(
                                    "b-container",
                                    { staticClass: "px-2 mt-1" },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _vm.specieltyDataCopy.id
                                                ? _c("h4", [
                                                    _vm._v(
                                                      "\n                        Edit Specialty Override\n                      "
                                                    ),
                                                  ])
                                                : _c("h4", [
                                                    _vm._v("Add Specialty"),
                                                  ]),
                                              _c("h5", [
                                                _vm._v(
                                                  "\n                        Specialty that need a referral and/or authorization\n                      "
                                                ),
                                              ]),
                                              _vm.specieltyDataCopy.insuranceId
                                                ? _c("p", [
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                        Specialty:\n                        " +
                                                        _vm._s(
                                                          _vm.specieltyDataCopy
                                                            .specialty
                                                            ? _vm
                                                                .specieltyDataCopy
                                                                .specialty.name
                                                            : ""
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                        Need Referral:\n                        " +
                                                        _vm._s(
                                                          _vm.specieltyDataCopy
                                                            .needReferral ==
                                                            true
                                                            ? "YES"
                                                            : "NO"
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                        Need Authorization:\n                        " +
                                                        _vm._s(
                                                          _vm.specieltyDataCopy
                                                            .needAuthrization ==
                                                            true
                                                            ? "YES"
                                                            : "NO"
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.specieltyDataCopy.id
                                                ? _c("h5", [
                                                    _c("br"),
                                                    _vm._v("Override to:"),
                                                  ])
                                                : _vm._e(),
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { label: "Specialty" },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Specialty",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "single-select",
                                                              {
                                                                attrs: {
                                                                  optionsLabel:
                                                                    "name",
                                                                  optionsRepo:
                                                                    _vm.specialtyRepo,
                                                                  idsToDisable:
                                                                    _vm.tableItems
                                                                      .filter(
                                                                        function (
                                                                          x
                                                                        ) {
                                                                          return x.insurancePlanId
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function (
                                                                          x
                                                                        ) {
                                                                          return x.specialtyId
                                                                        }
                                                                      ),
                                                                  disabeld:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .insuranceId !==
                                                                      null &&
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .id
                                                                      ? true
                                                                      : false,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .specialtyId,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.specieltyDataCopy,
                                                                        "specialtyId",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "specieltyDataCopy.specialtyId",
                                                                },
                                                              }
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Procedure Needs Referral",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    staticClass:
                                                      "demo-inline-spacing pt-0",
                                                    attrs: {
                                                      name: "ProcedureReferral",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "b-form-radio",
                                                              {
                                                                staticClass:
                                                                  "pt-0 mt-0",
                                                                attrs: {
                                                                  name: "ProcedureReferral",
                                                                  value: true,
                                                                  required: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .needReferral,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.specieltyDataCopy,
                                                                        "needReferral",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "specieltyDataCopy.needReferral",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            Yes\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-radio",
                                                              {
                                                                staticClass:
                                                                  "pt-0 mt-0",
                                                                attrs: {
                                                                  name: "ProcedureReferral",
                                                                  value: false,
                                                                  required: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .needReferral,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.specieltyDataCopy,
                                                                        "needReferral",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "specieltyDataCopy.needReferral",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            No\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Procedure Needs Authorization",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    staticClass:
                                                      "demo-inline-spacing pt-0",
                                                    attrs: {
                                                      name: "ProcedureAuthorization",
                                                      rules: "required",
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "b-form-radio",
                                                              {
                                                                staticClass:
                                                                  "pt-0 mt-0",
                                                                attrs: {
                                                                  name: "ProcedureAuthorization",
                                                                  value: true,
                                                                  required: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .needAuthrization,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.specieltyDataCopy,
                                                                        "needAuthrization",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "specieltyDataCopy.needAuthrization",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            Yes\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-form-radio",
                                                              {
                                                                staticClass:
                                                                  "pt-0 mt-0",
                                                                attrs: {
                                                                  name: "ProcedureAuthorization",
                                                                  value: false,
                                                                  required: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .specieltyDataCopy
                                                                      .needAuthrization,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.specieltyDataCopy,
                                                                        "needAuthrization",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "specieltyDataCopy.needAuthrization",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            No\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }