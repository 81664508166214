var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: { copyData: _vm.insurancePlansCopy },
              on: {
                "update:copyData": function ($event) {
                  _vm.insurancePlansCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.insurancePlansCopy = $event
                },
                save: _vm.validationForm,
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "b-form",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-alert",
                                { attrs: { variant: "primary", show: "" } },
                                [
                                  _c("div", { staticClass: "alert-body" }, [
                                    _c(
                                      "span",
                                      [
                                        _c("feather-icon", {
                                          staticClass: "mr-25",
                                          attrs: {
                                            icon: "AlertCircleIcon",
                                            size: "18",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    Data on this page is derived from the\n                    "
                                        ),
                                        _c("strong", [_vm._v("company info")]),
                                        _vm._v(
                                          "\n                    , you can override specific fields by clicking the\n                    "
                                        ),
                                        _c("feather-icon", {
                                          staticClass:
                                            "ml-30 mr-30 secondary-text cursor-pointer",
                                          attrs: {
                                            icon: "EditIcon",
                                            size: "16",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    icon\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c("input-override", {
                                attrs: {
                                  label: "Eligibility Payer ID",
                                  overrideData: _vm.insurancePlansCopy.insurance
                                    ? _vm.insurancePlansCopy.insurance
                                        .eligibilityPayorId
                                    : "",
                                },
                                model: {
                                  value:
                                    _vm.insurancePlansCopy.eligibilityPayorId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityPayorId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "insurancePlansCopy.eligibilityPayorId",
                                },
                              }),
                              _c("email-phon-fax-override", {
                                attrs: {
                                  sourceEmails:
                                    _vm.insurancePlansCopy.eligibilityEmails,
                                  sourcePhones:
                                    _vm.insurancePlansCopy.eligibilityPhones,
                                  overrideEmails: _vm.insurancePlansCopy
                                    .insurance
                                    ? _vm.insurancePlansCopy.insurance
                                        .eligibilityEmails
                                    : [],
                                  overridePhones: _vm.insurancePlansCopy
                                    .insurance
                                    ? _vm.insurancePlansCopy.insurance
                                        .eligibilityPhones
                                    : [],
                                },
                                on: {
                                  "update:sourceEmails": function ($event) {
                                    return _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityEmails",
                                      $event
                                    )
                                  },
                                  "update:source-emails": function ($event) {
                                    return _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityEmails",
                                      $event
                                    )
                                  },
                                  "update:sourcePhones": function ($event) {
                                    return _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityPhones",
                                      $event
                                    )
                                  },
                                  "update:source-phones": function ($event) {
                                    return _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityPhones",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4", sm: "6" } },
                            [
                              _c("address-component", {
                                attrs: {
                                  addressOverride: _vm.insurancePlansCopy
                                    .insurance
                                    ? _vm.insurancePlansCopy.insurance
                                        .eligibilityAddress
                                    : {},
                                  withOverride: true,
                                },
                                model: {
                                  value:
                                    _vm.insurancePlansCopy.eligibilityAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.insurancePlansCopy,
                                      "eligibilityAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "insurancePlansCopy.eligibilityAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mt-5", attrs: { cols: "12" } },
                        [
                          _c("h5", { staticClass: "mb-1" }, [
                            _c("b", [
                              _vm._v("Web Links (Log-In Details And Methods)"),
                            ]),
                          ]),
                          _c("weblinks-cc", {
                            attrs: {
                              webLinksToFormField: "insurancePlanId",
                              filterByField: "InsurancePlanId",
                              filterByOverrideField: "insuranceId",
                              filterByOverridevalue:
                                _vm.insurancePlansCopy.insuranceId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }