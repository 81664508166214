var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(
        _vm.overrideEmails.filter(function (x) {
          return x.isDeactivated == false
        }),
        function (email, index) {
          return [
            _c(
              "b-form-group",
              {
                staticClass: "mr-1",
                attrs: { label: "Company Email #" + (index + 1) },
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-form-input", {
                      attrs: { disabled: true },
                      model: {
                        value: _vm.overrideEmails[index].emailAddress,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.overrideEmails[index],
                            "emailAddress",
                            $$v
                          )
                        },
                        expression: "overrideEmails[index].emailAddress",
                      },
                    }),
                    _c(
                      "b-input-group-append",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(40, 199, 111, 0.15)",
                            expression: "'rgba(40, 199, 111, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        attrs: { "is-text": "" },
                        on: {
                          click: function ($event) {
                            email.isDeactivated = true
                          },
                        },
                      },
                      [
                        _c("b-icon-x-circle", {
                          staticClass: "mt XCircele",
                          attrs: { scale: "1", variant: "danger" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        }
      ),
      _vm._l(_vm.emails, function (email, $index) {
        return _c(
          "span",
          { key: $index + "email" },
          [
            _c(
              "b-form-group",
              { attrs: { label: "Email #" + ($index + 1) } },
              [
                _c("validation-provider", {
                  staticClass: "align-items-center",
                  attrs: {
                    validate: email,
                    "data-vv-as": "email",
                    name: "Email",
                    rules: "required",
                    type: "email",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("b-form-input", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    type: "email",
                                    placeholder: "",
                                    required: "",
                                  },
                                  model: {
                                    value: email.emailAddress,
                                    callback: function ($$v) {
                                      _vm.$set(email, "emailAddress", $$v)
                                    },
                                    expression: "email.emailAddress",
                                  },
                                }),
                                $index >= 1
                                  ? _c("feather-icon", {
                                      staticClass: "text-danger cursor-pointer",
                                      attrs: { icon: "XIcon", size: "20" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItem("email", $index)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(40, 199, 111, 0.15)",
              expression: "'rgba(40, 199, 111, 0.15)'",
              modifiers: { 400: true },
            },
          ],
          staticClass: "text-left align-left",
          attrs: { variant: "flat-primary", size: "sm" },
        },
        [
          _c(
            "span",
            {
              staticClass: "align-left",
              on: {
                click: function ($event) {
                  return _vm.emails.push({ emailAddress: null })
                },
              },
            },
            [_vm._v("Add Email")]
          ),
        ]
      ),
      _c("br"),
      _c("br"),
      _vm._l(
        _vm.overridePhones.filter(function (x) {
          return x.isDeactivated == false
        }),
        function (phone, index) {
          return [
            _c(
              "b-col",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "mr-1",
                    attrs: { label: "Company Phone #" + (index + 1) },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.overridePhones[index].number,
                            callback: function ($$v) {
                              _vm.$set(_vm.overridePhones[index], "number", $$v)
                            },
                            expression: "overridePhones[index].number",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(40, 199, 111, 0.15)",
                                expression: "'rgba(40, 199, 111, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass: "XCircele",
                            attrs: { "is-text": "" },
                            on: {
                              click: function ($event) {
                                phone.isDeactivated = true
                              },
                            },
                          },
                          [
                            _c("b-icon-x-circle", {
                              staticClass: "mt XCircele",
                              attrs: { scale: "1", variant: "danger" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Type" } },
                  [
                    _c("validation-provider", {
                      staticClass: "align-items-center",
                      attrs: { name: "Type", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        disabled: "",
                                        options: Object.values(_vm.phoneTypes),
                                        required: "",
                                      },
                                      model: {
                                        value: phone.type,
                                        callback: function ($$v) {
                                          _vm.$set(phone, "type", $$v)
                                        },
                                        expression: "phone.type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        }
      ),
      _vm._l(
        _vm.phones.filter(function (x) {
          return x.type != _vm.phoneTypes.Fax
        }),
        function (phone, $index) {
          return _c(
            "b-row",
            { key: $index + "phone" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mr-1",
                      attrs: { label: "Phone #" + ($index + 1) },
                    },
                    [
                      _c("validation-provider", {
                        attrs: { name: "Phone", rules: "required" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    attrs: { placeholder: "", required: "" },
                                    model: {
                                      value: phone.number,
                                      callback: function ($$v) {
                                        _vm.$set(phone, "number", $$v)
                                      },
                                      expression: "phone.number",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Type" } },
                    [
                      _c("validation-provider", {
                        staticClass: "align-items-center",
                        attrs: { name: "Type", rules: "required" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: [
                                            { text: "Work", value: "Work" },
                                            { text: "Home", value: "Home" },
                                            {
                                              text: "Personal",
                                              value: "Personal",
                                            },
                                          ],
                                          required: "",
                                        },
                                        model: {
                                          value: phone.type,
                                          callback: function ($$v) {
                                            _vm.$set(phone, "type", $$v)
                                          },
                                          expression: "phone.type",
                                        },
                                      }),
                                      $index >= 1
                                        ? _c("feather-icon", {
                                            staticClass:
                                              "text-danger cursor-pointer ml-1",
                                            attrs: {
                                              icon: "XIcon",
                                              size: "36",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.removeItem(
                                                  "phone",
                                                  _vm.phones.indexOf(phone)
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(40, 199, 111, 0.15)",
              expression: "'rgba(40, 199, 111, 0.15)'",
              modifiers: { 400: true },
            },
          ],
          staticClass: "text-left align-left",
          attrs: { variant: "flat-primary", size: "sm" },
        },
        [
          _c(
            "span",
            {
              staticClass: "align-left",
              on: {
                click: function ($event) {
                  return _vm.phones.push({ number: null, type: null })
                },
              },
            },
            [_vm._v("Add Phone")]
          ),
        ]
      ),
      _c("br"),
      _c("br"),
      _vm._l(
        _vm.phones.filter(function (x) {
          return (
            x.type == _vm.phoneTypes.Fax &&
            x.status != _vm.generalStatus.Deleted
          )
        }),
        function (fax, $index) {
          return _c(
            "span",
            { key: $index + "fax" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Fax #" + ($index + 1) } },
                [
                  _c("validation-provider", {
                    staticClass: "align-items-center",
                    attrs: { name: "Fax", rules: "required" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "mr-1",
                                    attrs: { placeholder: "", required: "" },
                                    model: {
                                      value: fax.number,
                                      callback: function ($$v) {
                                        _vm.$set(fax, "number", $$v)
                                      },
                                      expression: "fax.number",
                                    },
                                  }),
                                  $index >= 1
                                    ? _c("feather-icon", {
                                        staticClass:
                                          "text-danger cursor-pointer",
                                        attrs: { icon: "XIcon", size: "20" },
                                        on: {
                                          click: function ($event) {
                                            _vm.removeItem(
                                              "fax",
                                              _vm.phones.indexOf(fax)
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(errors[0])),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(40, 199, 111, 0.15)",
              expression: "'rgba(40, 199, 111, 0.15)'",
              modifiers: { 400: true },
            },
          ],
          staticClass: "text-left align-left",
          attrs: { variant: "flat-primary", size: "sm" },
        },
        [
          _c(
            "span",
            {
              staticClass: "align-left",
              on: {
                click: function ($event) {
                  return _vm.phones.push({
                    number: null,
                    type: _vm.phoneTypes.Fax,
                  })
                },
              },
            },
            [_vm._v("Add Fax")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }