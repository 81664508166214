var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "form-component",
            {
              attrs: { copyData: _vm.insurancePlanCopy },
              on: {
                "update:copyData": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                "update:copy-data": function ($event) {
                  _vm.insurancePlanCopy = $event
                },
                save: _vm.validationForm,
                cancel: _vm.cancel,
              },
              model: {
                value: _vm.formData,
                callback: function ($$v) {
                  _vm.formData = $$v
                },
                expression: "formData",
              },
            },
            [
              _c(
                "b-form",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-alert",
                            { attrs: { variant: "primary", show: "" } },
                            [
                              _c("div", { staticClass: "alert-body" }, [
                                _c(
                                  "span",
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-25",
                                      attrs: {
                                        icon: "AlertCircleIcon",
                                        size: "18",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  Data on this page is derived from the\n                  "
                                    ),
                                    _c("strong", [_vm._v("company info")]),
                                    _vm._v(
                                      "\n                  , you can override specific fields by clicking the\n                  "
                                    ),
                                    _c("feather-icon", {
                                      staticClass:
                                        "ml-30 mr-30 secondary-text cursor-pointer",
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                    _vm._v(
                                      "\n                  icon\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("email-phon-fax-override", {
                            attrs: {
                              sourceEmails: _vm.insurancePlanCopy.appealEmails,
                              sourcePhones: _vm.insurancePlanCopy.appealPhones,
                              overrideEmails: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.appealEmails
                                : [],
                              overridePhones: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.appealPhones
                                : [],
                            },
                            on: {
                              "update:sourceEmails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "appealEmails",
                                  $event
                                )
                              },
                              "update:source-emails": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "appealEmails",
                                  $event
                                )
                              },
                              "update:sourcePhones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "appealPhones",
                                  $event
                                )
                              },
                              "update:source-phones": function ($event) {
                                return _vm.$set(
                                  _vm.insurancePlanCopy,
                                  "appealPhones",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4", sm: "6" } },
                        [
                          _c("address-component", {
                            attrs: {
                              addressOverride: _vm.insurancePlanCopy.insurance
                                ? _vm.insurancePlanCopy.insurance.appealAddress
                                : {},
                              withOverride: true,
                            },
                            model: {
                              value: _vm.addressCopy,
                              callback: function ($$v) {
                                _vm.addressCopy = $$v
                              },
                              expression: "addressCopy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }