var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("related-procedures", {
        attrs: {
          optionsRepo: _vm.procedureTypesRepo,
          relationsFilter: function () {
            return true
          },
          optionsLabel: "procType",
          relationsField: "excludeBenefits",
          relationToOptionsField: "procedureTypeId",
          relationToFormField: "insurancePlanId",
          relationsRepo: _vm.excludeBenefitsRepo,
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(procedureType)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.procedureType.procType) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(description)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(data.item.procedureType.description) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "sidebar-header",
            fn: function (item) {
              return [
                item.id
                  ? _c("h4", [_vm._v("Update Procedure Type ")])
                  : _c("h4", [_vm._v("Add Procedure Type ")]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }