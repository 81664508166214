

















































import {
  Component,
  ModelSync,
  Prop,
  PropSync,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupAddon,
  BFormRadio,
  BFormText,
  BFormGroup,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { required } from "vee-validate/dist/rules";
import _ from "lodash";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroup,
    BInputGroupAddon,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BFormText,
  },
})
export default class RadioOverride extends Vue {
  @ModelSync("inputData", "input")
  inputField!: Boolean | null;

  @Prop()
  label!: String;
  
  @PropSync("overrideData")
  overrideField!: Boolean;

  editButtonAllow = true;

  @Watch("inputField")
  inputFieldWatch() {
    console.log(this.inputField);
    if ( _.isNil(this.inputField)==false) {
      console.log(this.inputField);
      this.editButtonAllow = false;
    }
  }

  get radioValue() {
    if (this.inputField == null || this.inputField == undefined)
      return this.overrideField;
    else {
      return this.inputField;
    }
  }

  set radioValue(value) {
    if(this.editButtonAllow){
      this.overrideField=value
    }else{
      this.inputField=value;
    }
  }

  onEditClick() {
    if (this.editButtonAllow == false) {
      this.inputField = null;
    }
    this.editButtonAllow = !this.editButtonAllow;
  }
}
