var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: { label: _vm.label },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("b-form-text", [
                    _c(
                      "h6",
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.label) + "\n          "
                        ),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer text-secondary",
                          attrs: { icon: "EditIcon" },
                          on: { click: _vm.onEditClick },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("validation-provider", {
            staticClass: "demo-inline-spacing pt-0",
            attrs: { name: _vm.label, rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "b-form-radio",
                      {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          disabled: _vm.editButtonAllow,
                          name: _vm.label,
                          value: true,
                          required: "",
                        },
                        model: {
                          value: _vm.radioValue,
                          callback: function ($$v) {
                            _vm.radioValue = $$v
                          },
                          expression: "radioValue",
                        },
                      },
                      [_vm._v("\n        Yes\n      ")]
                    ),
                    _c(
                      "b-form-radio",
                      {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          disabled: _vm.editButtonAllow,
                          name: _vm.label,
                          value: false,
                          required: "",
                        },
                        model: {
                          value: _vm.radioValue,
                          callback: function ($$v) {
                            _vm.radioValue = $$v
                          },
                          expression: "radioValue",
                        },
                      },
                      [_vm._v("\n        No\n      ")]
                    ),
                    _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(errors[0])),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }