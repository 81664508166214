var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                _c("div", { staticClass: "alert-body" }, [
                  _c(
                    "span",
                    [
                      _c("feather-icon", {
                        staticClass: "mr-25",
                        attrs: { icon: "AlertCircleIcon", size: "18" },
                      }),
                      _vm._v(
                        "\n            Data on this page is derived from the\n            "
                      ),
                      _c("strong", [_vm._v("company info")]),
                      _vm._v(
                        "\n            , you can override specific fields by clicking the\n            "
                      ),
                      _c("feather-icon", {
                        staticClass:
                          "ml-30 mr-30 secondary-text cursor-pointer",
                        attrs: { icon: "EditIcon", size: "16" },
                      }),
                      _vm._v("\n            icon\n          "),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "px-2" },
            [
              _c("b-col", { staticClass: "mt-1 pl-2", attrs: { cols: "12" } }, [
                _c("h5", [
                  _c("b", [
                    _vm._v(
                      "Procedures that need a referral and/or authorization"
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("table-component", {
                    attrs: {
                      itemsRepo: _vm.authorizationSettingsRepo,
                      isAddEditSidbar: true,
                      sidebarOpen: _vm.sidebar,
                      fields: _vm.fields,
                      copyData: _vm.sidebarDataCopy,
                      sidebarData: _vm.sidebarData,
                      deleteFilter: function (x) {
                        return x.insurancePlanId
                      },
                      getByIdOnEditFilter: function (x) {
                        return x.insurancePlanId
                      },
                    },
                    on: {
                      "update:sidebarOpen": function ($event) {
                        _vm.sidebar = $event
                      },
                      "update:sidebar-open": function ($event) {
                        _vm.sidebar = $event
                      },
                      "update:copyData": function ($event) {
                        _vm.sidebarDataCopy = $event
                      },
                      "update:copy-data": function ($event) {
                        _vm.sidebarDataCopy = $event
                      },
                      "update:sidebarData": function ($event) {
                        _vm.sidebarData = $event
                      },
                      "update:sidebar-data": function ($event) {
                        _vm.sidebarData = $event
                      },
                      save: _vm.validationForm,
                      "on-delete": _vm.onDelete,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(procedureCode)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.cpt.procCode) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(procedureSource)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  data.item.insuranceId
                                    ? "INSURANCE"
                                    : "INSURANCE PLAN"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(needReferral)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  data.item.needReferral == true ? "YES" : "NO"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(needAuthrization)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  data.item.needAuthrization == true
                                    ? "YES"
                                    : "NO"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(
                              "validation-observer",
                              { ref: "simpleRules" },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _vm.sidebarDataCopy.id
                                      ? _c("h4", [_vm._v("Override Procedure")])
                                      : _c("h4", [_vm._v("Add Procedure")]),
                                    _c("br"),
                                    _c("h5", [
                                      _vm._v(
                                        "Override that need a referral and/or authorization"
                                      ),
                                    ]),
                                    _vm.sidebarDataCopy.insuranceId
                                      ? _c("p", [
                                          _c("br"),
                                          _vm._v(
                                            "\n                  Procedure Code:\n                  " +
                                              _vm._s(
                                                _vm.sidebarDataCopy.cpt
                                                  ? _vm.sidebarDataCopy.cpt
                                                      .procCode
                                                  : ""
                                              ) +
                                              "\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                  Need Referral:\n                  " +
                                              _vm._s(
                                                _vm.sidebarDataCopy
                                                  .needReferral == true
                                                  ? "YES"
                                                  : "NO"
                                              ) +
                                              "\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                  Need Authorization:\n                  " +
                                              _vm._s(
                                                _vm.sidebarDataCopy
                                                  .needAuthrization == true
                                                  ? "YES"
                                                  : "NO"
                                              ) +
                                              "\n                "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.sidebarDataCopy.id
                                      ? _c("h5", [
                                          _c("br"),
                                          _vm._v("Override to:"),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "b-form-group",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { label: "Procedure Code" },
                                      },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Procedure Code",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      "include-ids": [
                                                        _vm.sidebarDataCopy
                                                          .cptId,
                                                      ],
                                                      label: "procCode",
                                                      repo: _vm.procedureCodesRepo,
                                                      disabeld:
                                                        _vm.sidebarDataCopy
                                                          .insuranceId !==
                                                          null &&
                                                        _vm.sidebarDataCopy.id
                                                          ? true
                                                          : false,
                                                      relationField: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.sidebarDataCopy
                                                          .cptId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.sidebarDataCopy,
                                                          "cptId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "sidebarDataCopy.cptId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "Procedure Needs Referral",
                                        },
                                      },
                                      [
                                        _c("validation-provider", {
                                          staticClass:
                                            "demo-inline-spacing pt-0",
                                          attrs: {
                                            name: "ProcedureReferral",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        id: "ProcedureReferralYes",
                                                        value: true,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sidebarDataCopy
                                                            .needReferral,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sidebarDataCopy,
                                                            "needReferral",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sidebarDataCopy.needReferral",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Yes\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        id: "ProcedureReferralNo",
                                                        value: false,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sidebarDataCopy
                                                            .needReferral,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sidebarDataCopy,
                                                            "needReferral",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sidebarDataCopy.needReferral",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      No\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Procedure Needs Authorization",
                                        },
                                      },
                                      [
                                        _c("validation-provider", {
                                          staticClass:
                                            "demo-inline-spacing pt-0",
                                          attrs: {
                                            name: "ProcedureAuthorization",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        id: "ProcedureAuthorizationYes",
                                                        value: true,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sidebarDataCopy
                                                            .needAuthrization,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sidebarDataCopy,
                                                            "needAuthrization",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sidebarDataCopy.needAuthrization",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Yes\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        id: "ProcedureAuthorizationNo",
                                                        value: false,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sidebarDataCopy
                                                            .needAuthrization,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sidebarDataCopy,
                                                            "needAuthrization",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sidebarDataCopy.needAuthrization",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      No\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.tableItems,
                      callback: function ($$v) {
                        _vm.tableItems = $$v
                      },
                      expression: "tableItems",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }